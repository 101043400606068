import React from 'react'
import Layout from '../components/layout'

const BlogPage = () => (
  <Layout>
    <div id="wrapper">
      <h2>Medijska podrška</h2>
      <p>
        <a href="/">Povratak na sajt.</a>
      </p>
    </div>
  </Layout>
)

export default BlogPage
